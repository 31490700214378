@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
.buildimage{
  max-width: 100%;
  object-fit: scale-down;
  padding: 20px;
}
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: x-large;
}

.search-form {
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-backdrop {
  display: none;
}
.search-bar {
  width: 50%;
  border: none;
  padding: 20px;
}

.search-button {
  background-image: linear-gradient(to right, #373835 0%, #2575fc 100%);
  border: none;
  padding: 20px 40px;
  color: white;
}

.recipes {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.recipesearch {
  display: flex;
  flex-direction: column;
 background: #74a3a3;
 min-height: 150vh;
}

.App {
  display: flex;
  flex-direction: column;
 background: #74a3a3;
 min-height: 150vh;
 align-items: center;
}
.App3 {
  
  display: flex;
  flex-direction: column;
  justify-content: right;
 background: #2c3030;
}
.App2 {
  background: #74a3a3;
  padding: 0rem;
  text-align: center;
  align-items: center;
  object-fit: scale-down;
  min-height: 60rem;
  display: flex;
  flex-direction: column;
}
.Apizza{
  background: #af2b2b;
  padding: 0rem;
  text-align: center;
  align-items: center;
  object-fit: scale-down;
  min-height: 60rem;
  display: flex;
  flex-direction: column;
}
.Colaps{
  max-width: 19rem;
}

.information{
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
 
}

.informationinput {
  
 
  font-size: 20px;
  padding-left: 10px;
  margin: 5px;
}
h2{
  min-width: 110px;
}
.numinput {
  
  width: 50px;
  height: 2.5rem;
  font-size: 20px;
  padding-left: 10px;
 
}
.notes{
  width: 300px;
  height: 80px;
  font-size: 20px;
  padding-left: 10px;
  margin: 5px;
}

.informationbutton {
  width: 220px;
  height: 50px;
  margin-top: 15px;
}
.informationbutton:hover {
  cursor: pointer;
}



.inputT{
  font-size: 1.2rem;
  background-color: #fdfdfd;
  background-image: linear-gradient(45deg, #2c2a2a, #473e3e, rgb(10, 10, 44));
  background-size: 19%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  font-style: italic;
  font-family: Monaco;
  text-align: center;
  
  margin: 5px;
  
  
}
.input{
  color: #0d0347;
  margin: 5px;
  border: solid;
  border-width: 2px;
  
}
.input2{
  color: #792323;
  margin: 5px;
  border: solid;
  border-width: 2px;
  font-size: 1.5rem;
}
.displayimg{
  width:137px;
  height:105px;
  border: 1px solid black;
  background-position: center;
  background-size: cover;
}
.loginbutt{
  padding: 25px;
  align-items: center;
  display: flex;
  flex-direction: column;
  
}

.nasaimage{
  min-width: 17rem;
  padding: 5px;
  margin-bottom: 50px;
  padding-bottom: 45px;
  border-radius: 17rem;
  max-width: 30rem;
  max-height: 30rem;
  object-fit: scale-down;
  height: 100%;
  width: 100%;
}
.row{
  
 padding: 10px;
}
.title{
  font-size: 80px;
  background-color: #fdfdfd;
  background-image: linear-gradient(45deg, #2c2a2a, #473e3e, rgb(10, 10, 44));
  background-size: 19%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  font-style: italic;
  font-family: Monaco;
  text-align: center;
}
.rtitle{
  font-size: 30px;
  background-color: #fdfdfd;
  background-image: linear-gradient(45deg, #2c2a2a, #473e3e, rgb(10, 10, 44));
  background-size: 19%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  font-style: italic;
  font-family: Monaco;
  text-align: center;
}
.titlenasa{
  font-size: 40px;
  background-color: #fdfdfd;
  background-image: linear-gradient(45deg, #2c2a2a, #473e3e, rgb(10, 10, 44));
  background-size: 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  font-style: italic;
  font-family: Monaco;
  text-align: center;
}
.apizzasearch{
  
  font-size: 2rem;
  height: 4rem;
  width: 20rem;
}

.Col{

  justify-content: center;
  text-align: center;
  align-items: center;
}
.Paragraph{
  min-width: 17rem;
  border-radius: 17rem;
  max-width: 35rem;
  max-height: 80rem;
  object-fit: scale-down;
  height: 100%;
  width: 100%;
  font-size: 1.2rem;
  font-family: Garamond;
  color: rgb(71, 61, 59);
  padding: 25px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: .5rem;
  background-color: #e9d9c3;
}

.ParagraphA{
  min-width: 17rem;
  border-radius: 17rem;
  max-width: 35rem;
  max-height: 80rem;
  object-fit: scale-down;
  
  font-size: 1.2rem;
  font-family: Garamond;
  color: rgb(71, 61, 59);
  padding: 25px;
  margin-bottom: 10px;
  border-radius: .5rem;
  background-color: #f7e3d0;
}
.employees {

  justify-content: center;
  align-items: center;
  background-color:#ffffff ;
}
.button{
  justify-content: left;
  align-items: right;
  height: 1.7rem;
  font-size: .9rem;
  width:10.65rem;
}
.buttonbook{
  justify-content: left;
  align-items: right;
  height: 2.7rem;
  font-size: .9rem;
  width:15.65rem;
}
.buttont{
  justify-content: left;
  align-items: right;
  height: 2.5rem;
  font-size: .9rem;
  width:3.65rem;
}
.Buttoncheck{
  margin: 5px;
  height: 2rem;
  font-size: .9rem;
  width:3.65rem;
}
.employee{
  background-color: #b8c7cc;
  
  color: #000000;
  font-weight: bolder;
  
  width: 22rem;
  height: auto;
  border: 1px solid rgb(56, 56, 56);
  border-block-width: 10px;
  
  margin: 15px;
  padding: 5px;
}

.employeeh3 {
  margin: 20px;
  font-size: 14px;
}

.tableinfo{
  height: 385px;
  width: 300px;
}
.schedule {
  display: flex;
  direction: row;
  justify-content: space-evenly;
}