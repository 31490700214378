.recipe {
    border-radius: 10px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: rgb(151, 151, 151);
    align-items: center;
    width: 40rem;
    transition: box-shadow 0.25s ease 0s, transform 0.25s ease 0s,
      transform 0.5s cubic-bezier(0.6, 0.2, 0.1, 1) 0s,
      opacity 0.5s cubic-bezier(0.6, 0.2, 0.1, 1) 0s;
  }
  
  .image {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    padding: 10rem;
  }